module.exports = [{
      plugin: require('/Users/michal/Documents/dev/projects/kancelaria-krotoszyn/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/michal/Documents/dev/projects/kancelaria-krotoszyn/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat"]}},
    },{
      plugin: require('/Users/michal/Documents/dev/projects/kancelaria-krotoszyn/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://adwokat-rybacka.pl"},
    },{
      plugin: require('/Users/michal/Documents/dev/projects/kancelaria-krotoszyn/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('/Users/michal/Documents/dev/projects/kancelaria-krotoszyn/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
